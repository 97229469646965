<!-- Nav 15.11.2018 -->
<template>
    <nav v-on-clickaway="close" :class="[{ '--open': $store.state.app.nav_opened, '--shadow': shadow, '--fixed': fixed, '--header': header }, '--'+position]">
        <div v-if="powered_by_img" class="nav__brand">
            <img :src="powered_by_img" />
        </div>
        <div v-if="brand && brand_img" class="nav__brand" :title="$store.state.app.name + ' v' + $store.state.app.version">
            <router-link :to="brand_to">
                <img :src="brand_img" />
            </router-link>
        </div>

        <ul class="nav__menu" @click="close">
            <slot name="left"></slot>
            <li class="nav__spacer"></li>
            <slot name="right"></slot>
        </ul>

        <ul class="nav__right">
            <slot name="nav_right"></slot>
        </ul>

        <div class="nav__burger" :class="'nav__burger--'+burger_position" @click="toggle"><span></span><span></span><span></span></div>

        <transition name="fade">
            <loading v-if="loading"></loading>
        </transition>
    </nav>
</template>

<script>
import { directive as onClickaway } from 'vue-clickaway';

export default {
    name: 'Nav',
    props: {
        brand: {
            type: Boolean,
            default: true,
        },
        brand_img: String,
        powered_by_img: String,
        brand_to: {
            type: String,
            default: '/',
        },
        burger_position: {
            type: String,
            default: 'left',
        },
        fixed: Boolean,
        header: Boolean,
        position: {
            type: String,
            default: 'top',
        },
        shadow: Boolean,
    },
    methods: {
        toggle() {
            this.$store.commit('nav', { action: 'toggle' });
        },
        close() {
            if (this.$store.state.app.nav_opened) {
                this.$store.commit('nav', { action: 'close' });
            }
        },
    },
    computed: {
        loading() {
            return this.$store.state.app.loading;
        },
    },
    directives: {
        onClickaway,
    },
};
</script>

<style>
    /* Custom */
    nav {
        --height: 5.2rem;
        --background_topbar: var(--bg-highlight);
        --background_dropdown: var(--bg-white);
        --color_link: var(--color-ondark-secondary);
        --color_link_active: var(--color-ondark-primary);
        --color_link_dropdown: var(--color-ondark-secondary);
        --color_link_active_dropdown: var(--color-ondark-primary);
        --dropdown_border_color: var(--color-onlight-border-darker);
    }
    /* End-Custom */

    nav {
        background: var(--background_topbar);
        color: var(--color_link);
        min-height: var(--height);
        position: relative;
        padding: 0 1rem;

        display: flex;
        flex: 0 0 auto;
        align-items: center;
        justify-content: flex-start;
    }
        nav.--fixed { left: 0; top: 0; position: sticky; right: 0; z-index: 30; }
        nav.--fixed.--bottom { bottom: 0; top: auto; position: fixed; }
        nav.--shadow { box-shadow: 0 0.4rem 0.8rem rgba(0,0,0,0.1); }
        nav.--bottom.--shadow { box-shadow: 0 -0.4rem 0.8rem rgba(0,0,0,0.1); }

        .nav__brand { flex: 0 0 auto; padding: 0.5rem 1rem 0.5rem 0; display: flex; align-items: center;}
        .nav__brand img { width: 4rem; height: 4rem; display: block; object-fit: contain; object-position: center; }
        .nav__brand + .nav__brand::before {
            content: '|';
            margin-right: 0.8rem;
            opacity: 0.4;
        }
        .nav__brand + .nav__brand img {
            width: 3rem;
            height: 3rem;
        }

        .nav__menu, .nav__right {
            flex: 1 1 auto;
            display: flex;
            align-items: center;
        }
            .nav__menu > li a:not(.button),
            .nav__right > li a:not(.button) {
                padding: 0 1rem;
                display: block;
                line-height: var(--height);
            }
            .nav__menu .button,
            .nav__right .button { margin-left: 0.4rem; }
            .nav__menu > li,
            .nav__right > li { position: relative; }
            .nav__menu > li a:not(.button):hover,
            .nav__menu > li a:not(.button).--active,
            .nav__menu > li a:not(.button).is-selected,
            .nav__right > li a:not(.button):hover,
            .nav__right > li a:not(.button).--active,
            .nav__right > li a:not(.button).is-selected {
                color: var(--color_link_active);
            }
            .nav__spacer {
                flex: 1 1 auto;
            }
            .nav__menu ul hr,
            .nav__right ul hr {
                background-color: var(--dropdown_border_color);
                border: none;
                display: none;
                height: 0.1rem;
                margin: 0.5rem 0 0;
                padding: 0;
            }

        .nav__burger { flex: 0 0 auto; cursor: pointer; height: 3.25rem; position: relative; width: 3.25rem; display: none; }
        .nav__burger--left { order: -1; }
        .nav__burger span { background-color: currentColor; display: block; height: 0.1rem; left: calc(50% - 0.8rem); position: absolute; transform-origin: center; transition-duration: 86ms; transition-property: background-color, opacity, transform; transition-timing-function: ease-out; width: 1.6rem; }
        .nav__burger span:nth-child(1) { top: calc(50% - 0.6rem); } .nav__burger span:nth-child(2) { top: calc(50% - 0.1rem); } .nav__burger span:nth-child(3) { top: calc(50% + 0.4rem); }
        .nav__burger:hover { background-color: rgba(0, 0, 0, 0.05); }
        nav.--open .nav__burger span:nth-child(1) { transform: translateY(0.5rem) rotate(45deg); } nav.--open .nav__burger span:nth-child(2) { opacity: 0; } nav.--open .nav__burger span:nth-child(3) { transform: translateY(-0.5rem) rotate(-45deg); }

        .nav__right {
            flex: 0 0 auto;
            text-align: right;
            justify-content: flex-end;
        }

        nav.--header { padding: 0 2rem; }

    @media screen and (max-width: 1023px) {
        nav:not(.--open):not(.--header) .nav__menu { display: none; }
        nav:not(.--header) .nav__menu {
            --height: 4rem;
            order: 4;
            flex-basis: 100%;
            flex-direction: column;
            align-items: flex-start;
            padding: 1rem 0;
        }
            nav.--open:not(.--header) {
                box-shadow: 0 1.6rem 2.4rem rgba(0,0,0,0.1);
                flex-wrap: wrap;
            }
            .nav__menu > li { flex: 0 0 100%; }
            .nav__menu li li { padding-left: 1rem; }
            nav.--fixed > .nav__menu {
                max-height: calc(100vh - var(--height));
                overflow: visible;
            }
        .nav__right { flex-grow: 1; flex-shrink: 1; overflow: hidden; }
        nav:not(.--header) .nav__burger { display: block; }
        .hide-nav-small { display: none; }
    }

    @media screen and (min-width: 1024px) {
        .hide-nav-large { display: none; }
        .nav__menu > li > a:not(.button){
            padding: 0 1rem;
            border-bottom: 0.2rem solid transparent;
            line-height: calc(var(--height) - 0.2rem);
        }
        .nav__menu > li > a:not(.button).--active,
        .nav__menu > li > a:not(.button).is-selected { border-color: var(--color_link_active); }

        .nav__menu > li ul {
            --height: 4rem;
            background-color: var(--background_dropdown);
            border-radius: 0.4rem;
            border: 1px solid var(--dropdown_border_color);
            box-shadow: 0 0.4rem 0.8rem rgba(0,0,0,0.1);
            left: 0;
            min-width: 100%;
            position: absolute;
            z-index: 20;
            padding: 0.5rem 0;
            display: block;
            opacity: 0;
            pointer-events: none;
            top: calc(95% + (-0.4rem));
            transform: translateY(-0.5rem);
            transition-duration: 86ms;
            transition-property: opacity, transform;
        }
            .nav__menu > li ul a:not(.button) { color: var(--color_link_dropdown); }
            .nav__menu > li ul a:not(.button):hover { color: var(--color_link_active_dropdown); }
            .nav__menu > li ul.--up { top: auto; bottom: calc(95% + 0.4rem); transform: translateY(0.5rem); }
            .nav__menu > li ul.--right { left: auto; right: 0; }
            .nav__menu li:hover ul,
            .nav__menu li.--open ul {
                opacity: 1; pointer-events: auto;
                transform: translateY(0);
            }
            .nav__menu ul li a {
                padding-right: 2.6rem !important;
                white-space: nowrap;
            }
            .nav__menu ul hr { display: block; }
    }

    nav loading {
        border-radius: 0.4rem;
        color: var(--color_link_active);
        height: 0.2rem;
        pointer-events: none;
        position: absolute;
        bottom: 0; left: 0;
        display: block;
        width: 100%;
        overflow: hidden;
    }
        nav loading::after {
            content: '';
            background: currentColor;
            transform: translateX(100%);
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            height: 100%;
            border-radius: 0;
            width: 100%;
            border: 0;
            margin: 0;
            animation: shift-rightwards 1s ease-in-out infinite;
        }
    @keyframes shift-rightwards { 0% { transform:translateX(-100%); } 40% { transform:translateX(0%); } 60% { transform:translateX(0%); } 100% { transform:translateX(100%); } }
</style>
